.contact-container{
    height:650px;
    h1{
        text-align: left;
        margin-left:400px;
        margin-top:100px;
        font-size:48px;
    }
    h2{
        text-align: left;
        margin-left: 460px;
        margin-top: -40px;
    }

    .resources{
        display: flex;
        flex-direction: row;
        text-align: left;
        border:1px solid rgb(114, 83, 83);
        width:440px;
        margin-left:700px;
  
        .phone-email,.online{
            padding-left: 10px;
        }
    }

}

@media screen and (max-width: 500px) {
    .contact-container{
        h1{
            margin-top: -10px;
            margin-left: 20px;
            font-size:30px;
        }
        h2{
            text-align: left;
            padding-top:10px;
            margin-left: 20px;
        }
    
        .resources{
            margin-left: auto;
            margin-right: 20px;
        }
    
    }
}
