.main-container{
  display: flex;
  justify-content: space-between;
  h1,h2,h3{
    font-family: 'Saira Condensed', sans-serif;

  }

  .home-container {
      height: 670px;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      margin-top:80px;
      margin-left: 400px;
      position: relative;
    
      .welcome-container {
        margin-bottom: 20px;
    
        .welcome {
          text-align: left;
          margin-left: 80px;
    
          h2 {
            font-size: 3em;
            margin-left: 20px;
          }
    
          h1 {
            font-size: 8em;
            margin-top: -90px;
            margin-left: 0px;
          }
    
          h3 {
            font-size: 2em;
            margin-top: -140px;
            margin-left: 60px;
          }
        }
    
        .mini-nav {
          display: flex;
          justify-content: center;
          align-items: flex-start;
          margin-top: -50px;
          margin-left: 350px;
    
          .nav-links {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-wrap: wrap;
    
            h3 {
              font-size: 24px;
              margin: 10px;
              text-decoration: none;
            }
          }
        }
      }
    }

    .headshot {
      border-radius: 100%;
      position: relative;
      top: 100px;
      right: 550px;
      width: 200px;
      height: auto;
    }
}


@media screen and (max-width: 576px) {
    .main-container{
      justify-content: flex-start;
      width:100%;
      margin: 0 auto;
      
      .home-container{
        margin-left:-50px;
        margin-top: -20px;

        .welcome-container{
          margin-bottom: 0px;
          
          .welcome {
            text-align: left;
            margin-left: 80px;
      
            h2 {
              font-size: 2.4em;
              margin-left: -10px;
            }
      
            h1 {
              font-size: 4em;
              margin-left: -10px;
              margin-top: -60px;
            }
      
            h3 {
              font-size: 2em;
              margin-top: -70px;
              margin-left: -8px;
            }
          }
          .mini-nav {
            display: flex;
            flex-direction: row;
            justify-content:flex-start;
            align-items: flex-start;
            margin-top: 0px;
            margin-left: 50px;
      
            .nav-links {
              display: flex;
              justify-content: center;
              align-items: center;
              flex-wrap: wrap;
      
              h3 {
                font-size: 1.5em;
                margin: 8px;
                text-decoration: none;
              }
            }
          }
        }
      }
    }

    .headshot {
      border-radius: 100%;
      position: absolute;
      right: 0px;
      width: 0;
      height: auto;
      top: 200px; /* Add this line to move the element down */
    }
}


.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 80vh;
  text-align: center;
}

.image-container {
  position: relative;
}

.dialog-bubble {
  width:500px;
  position: absolute;
  top: 300px;
  left: 100%; 
  transform: translateX(-50%);
  opacity: .9;
}

.links {
  margin-top: 10px; /* Add some space between the images and the links */
  text-align: center; /* Center the links horizontally */

}

.links a {
  display: inline-block; /* Display the links on the same line */
  margin-right: 10px; /* Add some spacing between the links */
}
