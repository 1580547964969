@import './home';
@import './about';
@import './contact';
@import './resume';
@import './projects';
@import './footer';
@import './navbar';
@import url('https://fonts.googleapis.com/css2?family=Oxygen:wght@300&family=Saira+Condensed&display=swap');

*{
  font-family: 'Oxygen', sans-serif;
  color: rgb(114, 83, 83);
}

h1{
  font-family: 'Saira Condensed', sans-serif;

}

body{
    background-color: rgb(254, 247, 234);
  }
  
  .App {
    text-align: center;
  }
  