.footer-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    height:66px;
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: rgb(192, 183, 183);

   
    .footer-nav {
        display: flex;
        justify-content: flex-start;
        align-items: center;
      
        a {
          margin-left: 10px;
        }
      
        img {
          width: 25px;
          height: 25px;
        }

        p{
          margin-left:10px;
        }
      }

      .logos {
        display: flex;
        justify-content: flex-end;
        align-items: center;
      
        img {
          height: 40px;
          margin-right: 20px;
        }
      }

  }


@media screen and (max-width: 500px) {
  .footer-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    height:50px;
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: rgb(192, 183, 183);

   
    .footer-nav {
        display: flex;
        justify-content: flex-start;
        align-items: center;
      
        a {
          margin-left: 10px;
        }
      
        img {
          width: 25px;
          height: 25px;
        }

        p{
          margin-left:10px;
        }
      }

      .logos {
        display: none;
      }

  }
}
