.about-container{
    height:710px;
    h1{
        text-align: left;
        font-size:48px;
        margin-top:50px;
        margin-left:300px;
    }
    .bio{
        display: flex;
        flex-direction: row;
        border:1px solid rgb(114, 83, 83);
        height: 480px;
        width:880px;
        margin: 0 auto;
        text-align: left;

    }
    h2{
        font-size: 22px;
        text-indent: 10%;
        padding-top: 30px;
        padding-left: 20px;
        width:50%;
    }
    img{
        height:400px;
        padding-top:40px;
        padding-right:20px;
        margin: 0 auto;

    }
}

@media screen and (max-width: 500px) {
    .about-container{
        height:710px;
        h1{
            text-align: left;
            font-size:48px;
            margin-top:12px;
            margin-left:14px;
        }
        .bio{
            display: flex;
            flex-direction: column;
            border:1px solid rgb(114, 83, 83);
            height: 480px;
            width:420px;
            margin: 0 auto;
            // margin-top:0;
            // margin-left:0px;
            text-align: left;
            overflow: hidden; /* add this line */
            .h2-container{
                width:200px;
            }
        }
        h2{
            font-size: 22px;
            margin: 0 auto;
            padding:10px;
            width:100%;
            margin-right: 10px;
        }
        img{
            display: none;
            height:400px;
            padding-top:40px;
            padding-right:20px;
            margin: 0 auto;
    
        }
    }
}
