h1{
    font-size: 48px;
    text-align: left;
    margin-left: 600px;
}

  @media screen and (max-width: 500px) {
    h1{
        margin-left: 28px;
    }

    iframe{
        width:400px;
    }
  }

