@import url("https://fonts.googleapis.com/css2?family=Oxygen:wght@300&family=Saira+Condensed&display=swap");
.main-container {
  display: flex;
  justify-content: space-between;
}
.main-container h1, .main-container h2, .main-container h3 {
  font-family: "Saira Condensed", sans-serif;
}
.main-container .home-container {
  height: 670px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 80px;
  margin-left: 400px;
  position: relative;
}
.main-container .home-container .welcome-container {
  margin-bottom: 20px;
}
.main-container .home-container .welcome-container .welcome {
  text-align: left;
  margin-left: 80px;
}
.main-container .home-container .welcome-container .welcome h2 {
  font-size: 3em;
  margin-left: 20px;
}
.main-container .home-container .welcome-container .welcome h1 {
  font-size: 8em;
  margin-top: -90px;
  margin-left: 0px;
}
.main-container .home-container .welcome-container .welcome h3 {
  font-size: 2em;
  margin-top: -140px;
  margin-left: 60px;
}
.main-container .home-container .welcome-container .mini-nav {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin-top: -50px;
  margin-left: 350px;
}
.main-container .home-container .welcome-container .mini-nav .nav-links {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
.main-container .home-container .welcome-container .mini-nav .nav-links h3 {
  font-size: 24px;
  margin: 10px;
  text-decoration: none;
}
.main-container .headshot {
  border-radius: 100%;
  position: relative;
  top: 100px;
  right: 550px;
  width: 200px;
  height: auto;
}

@media screen and (max-width: 576px) {
  .main-container {
    justify-content: flex-start;
    width: 100%;
    margin: 0 auto;
  }
  .main-container .home-container {
    margin-left: -50px;
    margin-top: -20px;
  }
  .main-container .home-container .welcome-container {
    margin-bottom: 0px;
  }
  .main-container .home-container .welcome-container .welcome {
    text-align: left;
    margin-left: 80px;
  }
  .main-container .home-container .welcome-container .welcome h2 {
    font-size: 2.4em;
    margin-left: -10px;
  }
  .main-container .home-container .welcome-container .welcome h1 {
    font-size: 4em;
    margin-left: -10px;
    margin-top: -60px;
  }
  .main-container .home-container .welcome-container .welcome h3 {
    font-size: 2em;
    margin-top: -70px;
    margin-left: -8px;
  }
  .main-container .home-container .welcome-container .mini-nav {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 0px;
    margin-left: 50px;
  }
  .main-container .home-container .welcome-container .mini-nav .nav-links {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }
  .main-container .home-container .welcome-container .mini-nav .nav-links h3 {
    font-size: 1.5em;
    margin: 8px;
    text-decoration: none;
  }
  .headshot {
    border-radius: 100%;
    position: absolute;
    right: 0px;
    width: 0;
    height: auto;
    top: 200px; /* Add this line to move the element down */
  }
}
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 80vh;
  text-align: center;
}

.image-container {
  position: relative;
}

.dialog-bubble {
  width: 500px;
  position: absolute;
  top: 300px;
  left: 100%;
  transform: translateX(-50%);
  opacity: 0.9;
}

.links {
  margin-top: 10px; /* Add some space between the images and the links */
  text-align: center; /* Center the links horizontally */
}

.links a {
  display: inline-block; /* Display the links on the same line */
  margin-right: 10px; /* Add some spacing between the links */
}

.about-container {
  height: 710px;
}
.about-container h1 {
  text-align: left;
  font-size: 48px;
  margin-top: 50px;
  margin-left: 300px;
}
.about-container .bio {
  display: flex;
  flex-direction: row;
  border: 1px solid rgb(114, 83, 83);
  height: 480px;
  width: 880px;
  margin: 0 auto;
  text-align: left;
}
.about-container h2 {
  font-size: 22px;
  text-indent: 10%;
  padding-top: 30px;
  padding-left: 20px;
  width: 50%;
}
.about-container img {
  height: 400px;
  padding-top: 40px;
  padding-right: 20px;
  margin: 0 auto;
}

@media screen and (max-width: 500px) {
  .about-container {
    height: 710px;
  }
  .about-container h1 {
    text-align: left;
    font-size: 48px;
    margin-top: 12px;
    margin-left: 14px;
  }
  .about-container .bio {
    display: flex;
    flex-direction: column;
    border: 1px solid rgb(114, 83, 83);
    height: 480px;
    width: 420px;
    margin: 0 auto;
    text-align: left;
    overflow: hidden; /* add this line */
  }
  .about-container .bio .h2-container {
    width: 200px;
  }
  .about-container h2 {
    font-size: 22px;
    margin: 0 auto;
    padding: 10px;
    width: 100%;
    margin-right: 10px;
  }
  .about-container img {
    display: none;
    height: 400px;
    padding-top: 40px;
    padding-right: 20px;
    margin: 0 auto;
  }
}
.contact-container {
  height: 650px;
}
.contact-container h1 {
  text-align: left;
  margin-left: 400px;
  margin-top: 100px;
  font-size: 48px;
}
.contact-container h2 {
  text-align: left;
  margin-left: 460px;
  margin-top: -40px;
}
.contact-container .resources {
  display: flex;
  flex-direction: row;
  text-align: left;
  border: 1px solid rgb(114, 83, 83);
  width: 440px;
  margin-left: 700px;
}
.contact-container .resources .phone-email, .contact-container .resources .online {
  padding-left: 10px;
}

@media screen and (max-width: 500px) {
  .contact-container h1 {
    margin-top: -10px;
    margin-left: 20px;
    font-size: 30px;
  }
  .contact-container h2 {
    text-align: left;
    padding-top: 10px;
    margin-left: 20px;
  }
  .contact-container .resources {
    margin-left: auto;
    margin-right: 20px;
  }
}
h1 {
  font-size: 48px;
  text-align: left;
  margin-left: 600px;
}

@media screen and (max-width: 500px) {
  h1 {
    margin-left: 28px;
  }
  iframe {
    width: 400px;
  }
}
.slider {
  width: 1000px;
  margin: 0 auto;
  border: 1px solid rgb(114, 83, 83);
}

.card {
  margin: 0 auto;
  width: 500px;
  text-align: left;
  padding-left: 10px;
  padding-bottom: 10px;
}
.card .card-img {
  width: 500px;
  height: 300px;
  margin: 0 auto;
}

@media screen and (max-width: 500px) {
  .slider {
    width: 450px;
    height: 550px;
    margin: 0 auto;
    padding-bottom: 14px;
  }
  .card {
    margin: 0 auto;
    width: 500px;
    text-align: left;
    padding-left: 10px;
    padding-bottom: 10px;
  }
  .card .card-img {
    width: 400px;
    height: 240px;
  }
}
.footer-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  height: 66px;
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: rgb(192, 183, 183);
}
.footer-container .footer-nav {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.footer-container .footer-nav a {
  margin-left: 10px;
}
.footer-container .footer-nav img {
  width: 25px;
  height: 25px;
}
.footer-container .footer-nav p {
  margin-left: 10px;
}
.footer-container .logos {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.footer-container .logos img {
  height: 40px;
  margin-right: 20px;
}

@media screen and (max-width: 500px) {
  .footer-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    height: 50px;
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: rgb(192, 183, 183);
  }
  .footer-container .footer-nav {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .footer-container .footer-nav a {
    margin-left: 10px;
  }
  .footer-container .footer-nav img {
    width: 25px;
    height: 25px;
  }
  .footer-container .footer-nav p {
    margin-left: 10px;
  }
  .footer-container .logos {
    display: none;
  }
}
.nav-bar {
  color: #FEF7EA;
  height: 80px;
  font-size: 18px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 40px;
  margin-right: 20px;
}
.nav-bar h3 {
  font-family: "Saira Condensed", sans-serif;
}
.nav-bar h3:hover {
  cursor: pointer;
  color: salmon;
}
.nav-bar h3:active {
  transition: 0.1s;
  font-size: 24px;
}

@media screen and (max-width: 500px) {
  .nav-bar {
    font-size: 14px;
    gap: 32px;
  }
  h3:active {
    transition: none;
    font-size: 0;
  }
}
* {
  font-family: "Oxygen", sans-serif;
  color: rgb(114, 83, 83);
}

h1 {
  font-family: "Saira Condensed", sans-serif;
}

body {
  background-color: rgb(254, 247, 234);
}

.App {
  text-align: center;
}/*# sourceMappingURL=style.css.map */