.nav-bar{
    color:#FEF7EA ;
    // border:1px solid black;
    height:80px;
    font-size: 18px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    gap:40px;
    margin-right: 20px;
    h3{
      font-family: 'Saira Condensed', sans-serif;
      &:hover{
        cursor:pointer;
        color: salmon;
      }
      &:active{
        transition: .1s;
        font-size:24px;
      }
    }
  }

  @media screen and (max-width: 500px) {
    .nav-bar {
      font-size:14px;
      gap:32px;
    }
    h3{
      &:active{
        transition:none;
        font-size:0;
      }
    }
  }

