.slider{
  width:1000px;
  margin: 0 auto;
  border:1px solid rgb(114, 83, 83);

}

.card{
  margin: 0 auto;
  width:500px;
  text-align: left;
  padding-left:10px;
  padding-bottom: 10px;
  .card-img{
    width:500px;
    height:300px;
    margin:0 auto;
  }
  
}


@media screen and (max-width: 500px) {
  .slider{
    width:450px;
    height:550px;
    margin: 0 auto;
    padding-bottom: 14px;
  }
  
  .card{
    margin: 0 auto;
    width:500px;
    text-align: left;
    padding-left:10px;
    padding-bottom: 10px;
    .card-img{
      width:400px;
      height:240px;
    }
    
  }

}

